import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["AppModal"], {
    "is-open": $props.isOpen,
    onClose: $setup.close
  }, {
    default: _withCtx(() => [
      _createVNode($setup["AddWebshopForm"], { onClose: $setup.close })
    ]),
    _: 1
  }, 8, ["is-open"]))
}