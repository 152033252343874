import axios from "axios";
import router from "@/router";
import { useUserStore } from "@/stores/user";
import { toast, type ToastOptions } from "vue3-toastify";
import AppToaster from "@/components/common/AppToaster.vue";
import routesName from "@/utils/constants/routesName";
import authApi from "@/services/authApi";

interface Subscriber {
  (token: string): void;
}

const baseURL = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : ``;
const axiosClient = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
let subscribers: Subscriber[] = [];
let isRefreshing = false;

function onRefreshed(accessToken: string): void {
  subscribers.forEach((cb) => cb(accessToken));
}

function subscribeTokenRefresh(cb: Subscriber): void {
  subscribers.push(cb);
}

axiosClient.interceptors.request.use(
  (request) => {
    const userStore = useUserStore();
    if (request.headers && userStore.accessToken) {
      request.headers.Authorization = `Bearer ${userStore.accessToken}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const userStore = useUserStore();
    const originalRequest = error.config;
    if (error.response.status === 401) {
      if (error.config.url === "auth/token/refresh") {
        userStore.clear();
        router.push({ name: routesName.login });
      }
      if (userStore.refreshToken) {
        if (!isRefreshing) {
          isRefreshing = true;
          authApi
            .refreshToken({ refreshToken: userStore.refreshToken })
            .then((response) => {
              const { accessToken, refreshToken } = response.data.data;
              userStore.setTokens({ accessToken, refreshToken });
              isRefreshing = false;
              onRefreshed(accessToken);
              subscribers = [];
            })
            .catch(() => {
              userStore.clear();
              router.push({ name: routesName.login });
            });
        }
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axiosClient(originalRequest));
          });
        });
      } else {
        userStore.clear();
        router.push({ name: routesName.login });
      }
    } else if (error.config.url !== "auth/token/refresh") {
      toast(AppToaster, {
        type: toast.TYPE.ERROR,
        data: {
          title: "Error",
          description: error?.response?.data?.error?.message,
        },
      } as ToastOptions);
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
