import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "toaster" }
const _hoisted_2 = { class: "toaster__icon-wrapper" }
const _hoisted_3 = { class: "toaster__content" }
const _hoisted_4 = { class: "toaster__title" }
const _hoisted_5 = { class: "toaster__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["toaster__icon", { 'toaster__icon--success': $props.toastProps?.type === 'success', 'toaster__icon--warning': $props.toastProps?.type === 'warning', 'toaster__icon--error': $props.toastProps?.type === 'error', 'toaster__icon--info': $props.toastProps?.type === 'info' }])
      }, null, 2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString($props.toastProps?.data.title), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString($props.toastProps?.data.description), 1)
    ]),
    _createElementVNode("div", {
      class: "toaster__close-button",
      onClick: _cache[0] || (_cache[0] = ($event) => { $props.closeToast && $props.closeToast($event); })
    })
  ]))
}