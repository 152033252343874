import { createApp } from "vue";
import App from "./App.vue";
import "@/registerServiceWorker";
import router from "./router";
import "@/scss/styles.scss";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { PiniaSharedState } from "pinia-shared-state";
import * as Sentry from "@sentry/vue";
import "broadcastchannel-polyfill";
import { Integrations } from "@sentry/tracing";
import VCalendar from "v-calendar";
import Vue3Toasity, { type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(PiniaSharedState({ enable: true, initialize: true, type: "native" }));

const app = createApp(App);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: "https://ac978442617e4a4fbbd9742d70955368@sentry.office.onix.ua/163",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["onix-systems-vue-onix-base.staging.onix.ua", /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
    environment: "development",
    logErrors: true,
  });
}

app
  .use(pinia)
  .use(router)
  .use(VCalendar, {})
  .use(Vue3Toasity, {
    autoClose: 5000,
    clearOnUrlChange: false,
    hideProgressBar: true,
    closeOnClick: false,
    dangerouslyHTMLString: true,
    icon: false,
    closeButton: false,
  } as ToastContainerOptions)
  .mount("#app");
