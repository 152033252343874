import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f0e3b392"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "empty-layout"
}
const _hoisted_2 = {
  key: 1,
  class: "main-layout"
}
const _hoisted_3 = { class: "main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return ($setup.isFullPageLayout)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            _createVNode(_Transition, {
              name: "fade",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        })
      ]))
    : ($setup.isLoggedIn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode($setup["AppHeader"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["AppSidebar"]),
            _createElementVNode("div", {
              class: _normalizeClass(["wrapper", { wide: $setup.layoutStore.isShortSidebar }])
            }, [
              _createVNode(_component_router_view, null, {
                default: _withCtx(({ Component }) => [
                  _createVNode(_Transition, {
                    name: "fade",
                    mode: "out-in"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              })
            ], 2)
          ]),
          _createVNode($setup["EnableExtensionModal"], {
            webshopId: $setup.userStore.currentWebshop?.id,
            isOpened: $setup.userStore.isShowInstallingGuide,
            onOnClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.userStore.setShowInstallingGuide(false)))
          }, null, 8, ["webshopId", "isOpened"])
        ]))
      : _createCommentVNode("", true)
}