import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
} from "vue-router";
import routes from "@/router/routes";
import { useUserStore } from "@/stores/user";
import routesName from "@/utils/constants/routesName";
import { UserRoleEnum } from "@/utils/enum/UserRoleEnum";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  const canAccess = (routeTo: RouteLocationNormalized): boolean => {
    return routeTo.meta.allowedRoles?.length
      ? routeTo.meta.allowedRoles.some((i) => userStore.userRole.includes(i))
      : true;
  };
  if (to.matched.some((record) => record.meta.protected)) {
    if (userStore.accessToken) {
      if (
        to.matched.some((record) => record.meta.allowedRoles?.length) &&
        !canAccess(to)
      ) {
        next({ name: routesName.dashboard });
        return;
      }
      next();
      return;
    } else if (to.query.accessToken && to.query.refreshToken) {
      userStore.setTokens({
        accessToken: String(to.query.accessToken),
        refreshToken: String(to.query.refreshToken),
      });
      const roles = String(to.query.roles).split(",") as UserRoleEnum[];
      userStore.setUserRole(roles);
      next();
      return;
    }
    next({
      path: "/login",
      query: { redirect: to.path },
    });
    return;
  }
  if (to.matched.some((record) => record.meta.notAuthorized)) {
    if (!userStore.accessToken) {
      next();
    } else {
      next({
        path: "/",
      });
    }
    return;
  }
  next();
});

export default router;
