const routesName = {
  login: "login",
  loginAs: "loginAs",
  forgotPassword: "forgotPassword",
  resetPassword: "resetPassword",
  registration: "registration",
  addWebshop: "addWebshop",
  addIntegration: "addIntegration",
  signUp: "signUp",
  emailVerification: "emailVerification",
  dashboard: "dashboard",
  setting: "setting",
  settingIntegration: "settingIntegration",
  settingBilling: "settingBilling",
  settingCompany: "settingCompany",
  orderBumps: "orderBumps",
  orderBumpsCreate: "orderBumpsCreate",
  orderBumpsEdit: "orderBumpsEdit",
  products: "products",
  recommendations: "recommendations",
  cartEditor: "cartEditor",
  cartEditorDrawer: "cartEditorDrawer",
  cartEditorRewards: "cartEditorRewards",
  cartEditorTerms: "cartEditorTerms",
  cartEditorBadges: "cartEditorBadges",
  notFound: "notFound",
};

export default routesName;
