import { defineStore } from "pinia";
import { computed, ref } from "vue";
import router from "@/router";
import routesName from "@/utils/constants/routesName";
import { Webshop } from "@/types/entities/Webshop";
import { Profile } from "@/types/entities/Profile";
import { useCookies } from "@vueuse/integrations/useCookies";
import cookiesOptions from "@/utils/cookiesOptions";
import { UserRoleEnum } from "@/utils/enum/UserRoleEnum";
import webshopApi from "@/services/webshopApi";

export const useUserStore = defineStore(
  "user",
  () => {
    const cookies = useCookies();
    const accessToken = ref<string>("");
    const refreshToken = ref<string>("");
    const savedEmail = ref<string>("");

    const setTokens = (payload: {
      accessToken: string;
      refreshToken: string;
    }): void => {
      refreshToken.value = payload.refreshToken;
      accessToken.value = payload.accessToken;
    };

    const setSavedEmail = (email: string): void => {
      savedEmail.value = email;
    };

    const defaultCurrentWebshop = {
      id: "",
      name: "",
      logoUrl: "",
      ecommercePlatform: "",
      userId: "",
      createdAt: "",
      updatedAt: "",
      isHidden: false,
      orderBumpLocales: [],
      currency: "",
    };
    const webshopsList = ref<Webshop[]>([]);
    const currentWebshop = ref<Webshop | null>(defaultCurrentWebshop);
    const isCurrentWebshopConnected = computed<boolean>(
      () => !!currentWebshop.value?.ecommercePlatform
    );
    const setWebshopsList = (arr: Webshop[], currentId?: string): void => {
      webshopsList.value = arr;
      if (currentId) {
        setCurrentWebshop(currentId);
      } else {
        setCurrentWebshop(arr[0]?.id);
      }
    };
    const setCurrentWebshop = (id: string): void => {
      currentWebshop.value =
        webshopsList.value.find((webshop) => webshop.id === id) || null;
      if (currentWebshop.value?.ecommercePlatform) {
        webshopApi.getInstallationGuide(currentWebshop.value.id).then((res) => {
          setShowInstallingGuide(!res.data.data.isThemeAppExtensionEnabled);
        });
      }
    };

    const userProfile = ref<Profile | null>();
    const setUserProfile = (profile: Profile): void => {
      userProfile.value = profile;
    };

    const clear = (): void => {
      accessToken.value = "";
      refreshToken.value = "";
      userProfile.value = null;
      userRole.value = [];
      webshopsList.value = [];
      currentWebshop.value = null;
      cookies.remove("upBoostUserId", cookiesOptions);
      cookies.remove("upBoostWebShopId", cookiesOptions);
      router.push({ name: routesName.login });
    };

    const userRole = ref<UserRoleEnum[]>([]);
    const setUserRole = (role: UserRoleEnum[]): void => {
      userRole.value = role;
    };
    const canRolesAccess = (roles: UserRoleEnum[]): boolean => {
      return userRole.value
        ? roles.some((i) => userRole.value.includes(i))
        : false;
    };
    const isUserRoleCustomer = computed<boolean>(() =>
      userRole.value.includes(UserRoleEnum.customer)
    );
    const isUserRoleSuperAdmin = computed<boolean>(() =>
      userRole.value.includes(UserRoleEnum.superAdmin)
    );
    const isUserRoleAdmin = computed<boolean>(() =>
      userRole.value.includes(UserRoleEnum.admin)
    );

    const isShowInstallingGuide = ref<boolean>(false);
    const setShowInstallingGuide = (isShow: boolean): void => {
      isShowInstallingGuide.value = isShow;
    };

    return {
      accessToken,
      refreshToken,
      savedEmail,
      setSavedEmail,
      setTokens,
      setWebshopsList,
      setCurrentWebshop,
      setUserProfile,
      userProfile,
      webshopsList,
      currentWebshop,
      isCurrentWebshopConnected,
      clear,
      userRole,
      setUserRole,
      canRolesAccess,
      isUserRoleCustomer,
      isUserRoleSuperAdmin,
      isUserRoleAdmin,
      isShowInstallingGuide,
      setShowInstallingGuide,
    };
  },
  {
    persist: {
      paths: [
        "accessToken",
        "refreshToken",
        "webshopsList",
        "currentWebshop",
        "userProfile",
        "userRole",
      ],
    },
  }
);
