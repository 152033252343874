import axiosClient from "./api/index";
import { ApiClientResponse } from "@/types/api/ApiClientResponse";
import { Webshop } from "@/types/entities/Webshop";
import { InstallationGuide } from "@/types/entities/InstallationGuide";

export default {
  addWebshop(payload: {
    name: string;
    file: string;
  }): Promise<ApiClientResponse<Webshop>> {
    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("file", payload.file);
    return axiosClient.post("web-shops", formData);
  },
  editWebshop(payload: {
    name: string;
    file: string;
    id: string;
  }): Promise<ApiClientResponse<Webshop>> {
    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("file", payload.file);
    return axiosClient.patch(`web-shops/${payload.id}`, formData);
  },
  getWebshops(disableHidden: boolean): Promise<ApiClientResponse<Webshop[]>> {
    const requestQueries = disableHidden ? { disableHidden: false } : {};
    return axiosClient.get("web-shops", { params: { ...requestQueries } });
  },
  getInstallationGuide(
    webShopId: string
  ): Promise<ApiClientResponse<InstallationGuide>> {
    return axiosClient.get(`web-shops/${webShopId}/installation-guide`);
  },
  updateInstallationGuide(
    webShopId: string,
    payload: InstallationGuide
  ): Promise<ApiClientResponse<InstallationGuide>> {
    return axiosClient.patch(
      `web-shops/${webShopId}/installation-guide`,
      payload
    );
  },
  getThemeAppExtensionUrl(
    webShopId: string
  ): Promise<ApiClientResponse<{ url: string }>> {
    return axiosClient.get(`web-shops/${webShopId}/theme-app-extension-url`);
  },
};
