import { RouteRecordRaw } from "vue-router";
import routesName from "@/utils/constants/routesName";
import { UserRoleEnum } from "@/utils/enum/UserRoleEnum";

const loadView = (path: string) => {
  return () => import(`@/views/${path}.vue`);
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: routesName.dashboard,
    component: loadView("DashboardView"),
    meta: { protected: true },
  },
  {
    path: "/setting",
    name: routesName.setting,
    component: loadView("SettingView"),
    meta: { protected: true, allowedRoles: [UserRoleEnum.customer] },
    children: [
      {
        path: "integration",
        name: routesName.settingIntegration,
        component: loadView("settingChildren/SettingIntegrationView"),
      },
      {
        path: "billing",
        name: routesName.settingBilling,
        component: loadView("settingChildren/SettingBillingView"),
      },
      {
        path: "company",
        name: routesName.settingCompany,
        component: loadView("settingChildren/SettingCompanyView"),
      },
    ],
    redirect: "/setting/company",
  },
  {
    path: "/cart-editor",
    name: routesName.cartEditor,
    component: loadView("CartEditorView"),
    meta: { protected: true },
    children: [
      {
        path: "drawer",
        name: routesName.cartEditorDrawer,
        component: loadView("cartEditorChildren/CartEditorDrawerView"),
      },
      {
        path: "rewards",
        name: routesName.cartEditorRewards,
        component: loadView("cartEditorChildren/CartEditorRewardsView"),
      },
      {
        path: "trust-badges",
        name: routesName.cartEditorBadges,
        component: loadView("cartEditorChildren/CartEditorBadgesView"),
      },
      {
        path: "terms",
        name: routesName.cartEditorTerms,
        component: loadView("cartEditorChildren/CartEditorTermsView"),
      },
    ],
    redirect: "/cart-editor/drawer",
  },
  {
    path: "/order-bumps",
    name: routesName.orderBumps,
    component: loadView("OrderBumpsView"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/order-bumps/create",
    name: routesName.orderBumpsCreate,
    component: loadView("OrderBumpsCreateView"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/order-bumps/edit/:id",
    name: routesName.orderBumpsEdit,
    component: loadView("OrderBumpsEditView"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/products",
    name: routesName.products,
    component: loadView("ProductsView"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/login-as",
    name: routesName.loginAs,
    component: loadView("LoginAsView"),
    meta: { fullPage: true },
  },
  {
    path: "/login",
    name: routesName.login,
    component: loadView("LoginView"),
    meta: { notAuthorized: true, fullPage: true },
  },
  {
    path: "/forgot-password",
    name: routesName.forgotPassword,
    component: loadView("ForgotPasswordView"),
    meta: { notAuthorized: true, fullPage: true },
  },
  {
    path: "/reset-password",
    name: routesName.resetPassword,
    component: loadView("ResetPasswordView"),
    meta: { notAuthorized: true, fullPage: true },
  },
  {
    path: "/registration",
    name: routesName.registration,
    component: loadView("RegistrationView"),
    meta: { notAuthorized: true, fullPage: true },
  },
  {
    path: "/add-webshop",
    name: routesName.addWebshop,
    component: loadView("AddWebshopView"),
    meta: {
      protected: true,
      fullPage: true,
      allowedRoles: [UserRoleEnum.customer],
    },
  },
  {
    path: "/add-integration",
    name: routesName.addIntegration,
    component: loadView("AddIntegrationView"),
    meta: {
      protected: true,
      fullPage: true,
      allowedRoles: [UserRoleEnum.customer],
    },
  },
  {
    path: "/email-verification",
    name: routesName.emailVerification,
    component: loadView("EmailVerificationView"),
    meta: { fullPage: true },
  },
  {
    path: "/sign-up",
    name: routesName.signUp,
    component: loadView("SignUpView"),
    meta: { notAuthorized: true, fullPage: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: routesName.notFound,
    meta: { protected: true },
    component: loadView("NotFoundView"),
  },
];

export default routes;
