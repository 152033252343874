import { defineStore } from "pinia";
import { ref } from "vue";

export const useLayoutStore = defineStore(
  "layout",
  () => {
    const isShortSidebar = ref<boolean>(false);

    const setSidebarState = (value: boolean) => {
      isShortSidebar.value = value;
    };
    return { isShortSidebar, setSidebarState };
  },
  {
    persist: {
      paths: ["isShortSidebar"],
    },
  }
);
