import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1052f0e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-user-panel" }
const _hoisted_2 = { class: "header-user-avatar" }
const _hoisted_3 = { class: "header-user-name" }
const _hoisted_4 = {
  class: "header-user-dropdown-menu",
  ref: "userDropdownMenuRef"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "header-user-dropdown-menu-item-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["header-user-dropdown", { opened: $setup.isUserDropdownOpened }])
    }, [
      _createElementVNode("button", {
        class: "header-user-dropdown-info",
        onClick: $setup.onUserInfo,
        ref: "userDropdownRef"
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString($setup.userStore.userProfile?.firstName.charAt(0)) + _toDisplayString($setup.userStore.userProfile?.lastName.charAt(0)), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString($setup.userStore.userProfile?.firstName) + " " + _toDisplayString($setup.userStore.userProfile?.lastName), 1)
      ], 512),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.allowedMenuOptions, (item) => {
          return (_openBlock(), _createElementBlock("button", {
            class: "header-user-dropdown-menu-item",
            key: item.label,
            onClick: ($event: any) => ($setup.onMenuItem(item.action))
          }, [
            _createElementVNode("img", {
              class: "header-user-dropdown-menu-item-icon",
              src: require(`@/assets/icons/${item.iconName}.svg`),
              alt: item.label
            }, null, 8, _hoisted_6),
            _createElementVNode("div", _hoisted_7, _toDisplayString(item.label), 1)
          ], 8, _hoisted_5))
        }), 128))
      ], 512)
    ], 2)
  ]))
}