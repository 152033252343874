import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f6cdc80"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn", { 'btn--primary': $setup.colorPrimary, 'btn--warning': $setup.colorWarning, 'btn--outline': $props.outline, 'btn--disabled': $props.disabled }]),
    type: $props.type,
    onClick: $setup.clickHandler
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 10, _hoisted_1))
}