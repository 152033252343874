import * as universal_cookie from "universal-cookie";

const cookiesOptions: universal_cookie.CookieSetOptions = {
  path: "/",
  expires: new Date(new Date().setDate(new Date().getDate() + 1)),
  domain: process.env.VUE_APP_COOKIE_DOMAIN,
  secure: true,
  sameSite: "lax",
};

export default cookiesOptions;
