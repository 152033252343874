import axiosClient from "./api/index";
import { ApiClientResponse } from "@/types/api/ApiClientResponse";
import { AxiosResponse } from "axios";
import { LoginResponseBody } from "@/types/api/LoginResponseBody";

export default {
  login(payload: {
    email: string;
    password: string;
  }): Promise<ApiClientResponse<LoginResponseBody>> {
    return axiosClient.post("auth/sign-in", payload);
  },
  signUp(payload: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }): Promise<
    ApiClientResponse<{ id: string; email: string; isVerified: boolean }>
  > {
    return axiosClient.post("auth/sign-up", payload);
  },
  emailVerification(payload: {
    email: string;
    verificationCode: string;
  }): Promise<ApiClientResponse<LoginResponseBody>> {
    return axiosClient.post("auth/verify-email", payload);
  },
  changeEmail(
    verificationCode: string
  ): Promise<ApiClientResponse<LoginResponseBody>> {
    return axiosClient.patch("auth/change-email", { verificationCode });
  },
  resendVerificationCode(payload: { email: string }): Promise<AxiosResponse> {
    return axiosClient.post("auth/resend-code", payload);
  },
  resendChangeEmailVerificationCode(): Promise<AxiosResponse> {
    return axiosClient.post("auth/resend-change-email-code");
  },
  refreshToken(payload: {
    refreshToken: string;
  }): Promise<
    ApiClientResponse<{ accessToken: string; refreshToken: string }>
  > {
    return axiosClient.post("auth/token/refresh", payload);
  },
  forgotPassword(payload: {
    email: string;
    callBackUrl: string;
  }): Promise<
    ApiClientResponse<{ accessToken: string; refreshToken: string }>
  > {
    return axiosClient.patch("auth/forgot-password", payload);
  },
  resetPassword(payload: {
    password: string;
    token: string;
  }): Promise<ApiClientResponse<null>> {
    return axiosClient.patch("auth/reset-password", payload);
  },
};
