import type { NavigationItem } from "@/types/entities/SidebarNavigation";
import { UserRoleEnum } from "@/utils/enum/UserRoleEnum";

const navigationItems: NavigationItem[] = [
  {
    label: "Dashboard",
    path: "/",
    iconName: "dashboard",
  },
  {
    label: "Integration",
    path: "/setting/integration",
    iconName: "integration",
    disallowedRoles: [UserRoleEnum.superAdmin, UserRoleEnum.admin],
  },
  {
    label: "Company",
    path: "/setting/company",
    iconName: "setting",
    disallowedRoles: [UserRoleEnum.superAdmin, UserRoleEnum.admin],
  },
  {
    label: "Billing",
    path: "/setting/billing",
    iconName: "billing",
    disallowedRoles: [UserRoleEnum.superAdmin, UserRoleEnum.admin],
  },
  {
    label: "Order bumps",
    path: "/order-bumps",
    iconName: "tools",
  },
  {
    label: "Products",
    path: "/products",
    iconName: "products",
  },
  {
    label: "Cart editor",
    path: "/cart-editor",
    iconName: "cart-outline",
    children: [
      {
        label: "Cart design",
        path: "/cart-editor/drawer",
      },
      {
        label: "Rewards",
        path: "/cart-editor/rewards",
      },
      {
        label: "Trust badges",
        path: "/cart-editor/trust-badges",
      },
      {
        label: "Terms & Conditions",
        path: "/cart-editor/terms",
      },
    ],
  },
];

export default navigationItems;
