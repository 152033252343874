import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e16bc00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field__wrapper" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = {
  key: 0,
  class: "field__legend"
}
const _hoisted_4 = { class: "field__legend-label" }
const _hoisted_5 = ["value", "type", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("fieldset", _hoisted_2, [
      ($props.type !== 'search')
        ? (_openBlock(), _createElementBlock("legend", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString($props.label), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        class: _normalizeClass(["field__input", { 'field__input--disabled': $props.disabled, 'field__input--error': $props.errorMessage, 'field__input--empty': $props.type === '' }]),
        ref: "input",
        value: $props.modelValue || $props.value,
        type: $setup.showPassword ? 'text' : $props.type,
        placeholder: $props.placeholder,
        disabled: $props.disabled,
        onfocus: "this.removeAttribute('readonly');",
        readonly: "",
        onInput: _cache[0] || (_cache[0] = (e) => $setup.changeHandler(e)),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => ($setup.emits('onBlur'))),
        onFocus: _cache[2] || (_cache[2] = ($event: any) => ($setup.emits('onFocus')))
      }, null, 42, _hoisted_5),
      _createElementVNode("div", {
        class: _normalizeClass(["field__icon", { 'field__icon--active': $props.modelValue && $props.type !== 'card', 'field__icon-card': $props.type === 'card', 'field__icon-email': $props.type === 'email', 'field__icon-phone': $props.type === 'phone', 'field__icon-lock': $props.type === 'password', 'field__icon-search': $props.type === 'search' }])
      }, null, 2),
      ($props.type === 'password')
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: _normalizeClass(["field__button-eye--off", { 'field__button-eye': $setup.showPassword }]),
            type: "button",
            onClick: $setup.showHidePassword
          }, null, 2))
        : _createCommentVNode("", true)
    ])
  ]))
}